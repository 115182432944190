.internalContainer {
	width: 100%;
	height: 100%;
	display: flex;
}

.adminContainer{
	overflow-y: hidden;
	height: 100vh;
}

.formContainer {
	width: calc(100% - 275px);
	flex: 5;
	background-color: #F4F4F4;
	height: calc(100vh - 4.5rem);
}
